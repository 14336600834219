import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Main.scss';

class Main extends Component {
  componentDidMount() {
    window.scrollTo(0,1);
  }

  componentDidUpdate() {
    window.scrollTo(0,1);
  }

  render() {
    const { children } = this.props;
    return (
      <main className="main">
        {children}
      </main>
    );
  }
}

Main.propTypes = {
  children: PropTypes.any,
};

Main.defaultProps = {
  children: null,
};

export default Main;
