import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import testTweets from '../../.netlify/functions/latestTweets.json';
import './LatestTweets.scss';

const { REACT_APP_ISDEV } = process.env;
const IS_DEV = REACT_APP_ISDEV === 'true';

const TWEETS_PATH = '/.netlify/functions/latestTweets';

const processTweetLinks = (text) => {
  // eslint-disable-next-line no-useless-escape
  let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  text = text.replace(exp, '<a href="$1" target="_blank">$1</a>');
  exp = /(^|\s)#(\w+)/g;
  text = text.replace(exp, '$1<a href="https://twitter.com/hashtag/$2?src=hash" target="_blank">#$2</a>');
  exp = /(^|\s)@(\w+)/g;
  text = text.replace(exp, '$1<a href="http://www.twitter.com/$2" target="_blank">@$2</a>');
  return text;
}

const parseMeta = (created_at, id) => {
  var created = moment(new Date(created_at));
  return (
    <a
      href={`https://twitter.com/JJP_Training/status/${id}`}
      title={created.format()}
      target="_blank"
      rel="noopener noreferrer"
    >
      {created.format('h:mm A - D MMM YYYY')}
    </a>
  );
}

class LatestTweets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tweets: null,
    };
  }

  componentDidMount() {
    if (IS_DEV) {
      setInterval(() => {
        this.setState({
          loading: false,
          tweets: testTweets,
        });
      }, Math.random() * 6000);
    } else {
      axios.get(TWEETS_PATH).then(({ data }) => {
        this.setState({
          loading: false,
          tweets: data,
        });
      });
    }
  }

  render() {
    const { loading, tweets } = this.state;
    return (
      <aside className="latestTweets">
        {
          loading
            ? <p className="latestTweets__loading">Loading tweets...</p>
            : tweets.map(tweet => (
              <article key={tweet.id_str} className="latestTweets__article">
                <div
                  className="latestTweets__article__content"
                  dangerouslySetInnerHTML={{ __html: processTweetLinks(tweet.text) }}
                />
                <footer className="latestTweets__article__meta">
                  {parseMeta(tweet.created_at, tweet.id_str)}
                </footer>
              </article>
            ))
        }
      </aside>
    );
  }
}

export default LatestTweets;
