import React from 'react';

export default './logo.svg';

export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -14 234 58">
    <g>
      <g enableBackground="new    ">
        <path className="fillable" fill="#212121" d="M76.412,4.697c-3.25,0.253-3.587,0.549-3.587,4.094v15.785c0,5.402-0.845,8.314-3.63,10.889
          c-1.436,1.309-2.87,2.11-3.925,2.448l-0.549-1.182c2.363-1.182,3.63-3.123,4.052-5.529c0.38-2.025,0.464-4.474,0.464-8.019V8.791
          c0-3.545-0.338-3.841-3.756-4.094V3.516h10.931V4.697z"/>
      </g>
      <g enableBackground="new    ">
        <path className="pathable" fill="none" stroke="#212121" d="M76.412,4.697c-3.25,0.253-3.587,0.549-3.587,4.094v15.785c0,5.402-0.845,8.314-3.63,10.889
          c-1.436,1.309-2.87,2.11-3.925,2.448l-0.549-1.182c2.363-1.182,3.63-3.123,4.052-5.529c0.38-2.025,0.464-4.474,0.464-8.019V8.791
          c0-3.545-0.338-3.841-3.756-4.094V3.516h10.931V4.697z"/>
      </g>
      <g enableBackground="new    ">
        <path className="fillable" fill="#212121" d="M88.186,4.697c-3.25,0.253-3.587,0.549-3.587,4.094v15.785c0,5.402-0.845,8.314-3.63,10.889
          c-1.436,1.309-2.87,2.11-3.925,2.448l-0.549-1.182c2.363-1.182,3.63-3.123,4.052-5.529c0.38-2.025,0.464-4.474,0.464-8.019V8.791
          c0-3.545-0.338-3.841-3.756-4.094V3.516h10.931V4.697z"/>
      </g>
      <g enableBackground="new    ">
        <path className="pathable" fill="none" stroke="#212121" d="M88.186,4.697c-3.25,0.253-3.587,0.549-3.587,4.094v15.785c0,5.402-0.845,8.314-3.63,10.889
          c-1.436,1.309-2.87,2.11-3.925,2.448l-0.549-1.182c2.363-1.182,3.63-3.123,4.052-5.529c0.38-2.025,0.464-4.474,0.464-8.019V8.791
          c0-3.545-0.338-3.841-3.756-4.094V3.516h10.931V4.697z"/>
      </g>
      <g enableBackground="new    ">
        <path className="fillable" fill="#212121" d="M98.905,3.516c2.997,0,5.402,0.506,7.091,1.772c1.646,1.224,2.743,3.166,2.743,5.951
          c0,5.444-4.136,8.104-8.23,8.652c-0.633,0.042-1.435,0.042-1.898,0l-3.124-0.802v6.626c0,3.587,0.38,3.799,4.179,4.052v1.182
          H88.228v-1.182c3.376-0.253,3.714-0.591,3.714-4.094V8.791c0-3.714-0.338-3.883-3.461-4.094V3.516H98.905z M95.486,17.697
          c0.549,0.211,1.688,0.506,3.081,0.506c2.744,0,6.12-1.477,6.12-6.837c0-4.558-2.912-6.5-6.373-6.5
          c-1.182,0-2.068,0.211-2.363,0.506c-0.338,0.295-0.465,0.802-0.465,1.983V17.697z"/>
      </g>
      <g enableBackground="new    ">
        <path className="pathable" fill="none" stroke="#212121" d="M98.905,3.516c2.997,0,5.402,0.506,7.091,1.772c1.646,1.224,2.743,3.166,2.743,5.951
          c0,5.444-4.136,8.104-8.23,8.652c-0.633,0.042-1.435,0.042-1.898,0l-3.124-0.802v6.626c0,3.587,0.38,3.799,4.179,4.052v1.182
          H88.228v-1.182c3.376-0.253,3.714-0.591,3.714-4.094V8.791c0-3.714-0.338-3.883-3.461-4.094V3.516H98.905z M95.486,17.697
          c0.549,0.211,1.688,0.506,3.081,0.506c2.744,0,6.12-1.477,6.12-6.837c0-4.558-2.912-6.5-6.373-6.5
          c-1.182,0-2.068,0.211-2.363,0.506c-0.338,0.295-0.465,0.802-0.465,1.983V17.697z"/>
      </g>
      <g enableBackground="new    ">
        <path className="fillable" fill="#212121" d="M123.049,6.496h-8.355V3.479h20.338v3.016h-8.396v24.454h-3.586V6.496z"/>
      </g>
      <g enableBackground="new    ">
        <path className="fillable" fill="#212121" d="M133.653,18.277c0-2.17-0.038-4.035-0.152-5.747h2.93l0.114,3.615h0.152
          c0.838-2.474,2.854-4.034,5.1-4.034c0.381,0,0.646,0.038,0.951,0.114v3.159c-0.342-0.076-0.685-0.114-1.142-0.114
          c-2.359,0-4.034,1.788-4.49,4.3c-0.076,0.457-0.152,0.99-0.152,1.561v9.818h-3.311V18.277z"/>
        <path className="fillable" fill="#212121" d="M155.118,30.949l-0.266-2.321h-0.114c-1.027,1.446-3.007,2.74-5.633,2.74c-3.729,0-5.632-2.626-5.632-5.29
          c0-4.452,3.958-6.888,11.074-6.85v-0.381c0-1.522-0.419-4.262-4.187-4.262c-1.712,0-3.501,0.533-4.795,1.37l-0.761-2.207
          c1.522-0.989,3.729-1.636,6.051-1.636c5.632,0,7.002,3.843,7.002,7.535v6.889c0,1.598,0.076,3.158,0.305,4.414H155.118z
          M154.624,21.55c-3.653-0.076-7.802,0.57-7.802,4.147c0,2.17,1.446,3.197,3.159,3.197c2.397,0,3.919-1.522,4.452-3.083
          c0.114-0.343,0.19-0.723,0.19-1.065V21.55z"/>
        <path className="fillable" fill="#212121" d="M166.006,7.355c0.038,1.142-0.8,2.055-2.132,2.055c-1.18,0-2.017-0.913-2.017-2.055
          c0-1.18,0.875-2.093,2.093-2.093C165.206,5.262,166.006,6.175,166.006,7.355z M162.276,30.949V12.53h3.349v18.419H162.276z"/>
      </g>
      <g enableBackground="new    ">
        <path className="fillable" fill="#212121" d="M169.469,17.516c0-1.902-0.038-3.463-0.152-4.985h2.968l0.19,3.044h0.076
          c0.913-1.75,3.045-3.462,6.089-3.462c2.55,0,6.508,1.522,6.508,7.839v10.998h-3.349V20.332c0-2.969-1.104-5.442-4.263-5.442
          c-2.207,0-3.92,1.561-4.49,3.425c-0.152,0.419-0.229,0.99-0.229,1.561v11.074h-3.349V17.516z"/>
      </g>
      <g enableBackground="new    ">
        <path className="fillable" fill="#212121" d="M192.23,7.355c0.038,1.142-0.8,2.055-2.132,2.055c-1.18,0-2.017-0.913-2.017-2.055
          c0-1.18,0.875-2.093,2.093-2.093C191.431,5.262,192.23,6.175,192.23,7.355z M188.501,30.949V12.53h3.349v18.419H188.501z"/>
        <path className="fillable" fill="#212121" d="M196.418,17.516c0-1.902-0.038-3.463-0.152-4.985h2.968l0.19,3.044h0.076
          c0.913-1.75,3.045-3.462,6.089-3.462c2.55,0,6.508,1.522,6.508,7.839v10.998h-3.349V20.332c0-2.969-1.104-5.442-4.263-5.442
          c-2.207,0-3.92,1.561-4.49,3.425c-0.152,0.419-0.229,0.99-0.229,1.561v11.074h-3.349V17.516z"/>
        <path className="fillable" fill="#212121" d="M232.422,12.53c-0.076,1.332-0.152,2.816-0.152,5.062v10.693c0,4.225-0.838,6.812-2.626,8.41
          c-1.789,1.675-4.376,2.207-6.698,2.207c-2.207,0-4.643-0.532-6.127-1.521l0.838-2.55c1.218,0.761,3.12,1.446,5.403,1.446
          c3.426,0,5.937-1.789,5.937-6.432v-2.055h-0.075c-1.028,1.712-3.007,3.082-5.861,3.082c-4.566,0-7.839-3.882-7.839-8.981
          c0-6.241,4.071-9.78,8.296-9.78c3.196,0,4.947,1.674,5.746,3.197h0.076l0.152-2.778H232.422z M228.959,19.799
          c0-0.57-0.038-1.065-0.19-1.521c-0.609-1.941-2.245-3.54-4.681-3.54c-3.197,0-5.48,2.702-5.48,6.964
          c0,3.615,1.827,6.621,5.442,6.621c2.055,0,3.919-1.294,4.643-3.425c0.19-0.571,0.267-1.218,0.267-1.789V19.799z"/>
      </g>
      <g>
        <path className="fillable" fill="#212121" d="M47.377-6.072c-0.023-1.645-0.698-1.784-1.425-1.784s-1.553,0.332-1.553,1.88
          c0,1.549-0.047,7.407-0.047,8.323c0,0.629-2.274,1.645-2.274,1.645s-3.387,1.889-5.422,2.807
          c-2.031,0.919-2.611,0.873-2.611,0.873s-0.192-0.146-0.582-0.193c-0.388-0.05-0.681-0.097-0.681-0.097h-0.448
          c-0.791,1.363-2.081,2.449-3.808,2.449c-1.711,0-2.989-1.062-3.784-2.406l-0.765,0.003c0,0-0.579-0.046-1.503,0.29
          c-0.915,0.34-2.66,1.985-2.66,1.985s-4.936,5.226-6.144,6.392c-1.213,1.158-2.082,2.515-2.518,3.92
          c-0.437,1.397-1.611,4.316-2.128,6.387c-0.271,1.066-0.317,3.013,1.475,3.013c1.788,0,1.877-1.252,1.946-2.819
          c0.043-0.873,1.514-4.163,1.854-5.083c0.335-0.919,1.062-2.224,1.741-2.853c0.676-0.63,5.129-3.245,5.129-3.245
          s0.776,1.452,1.405,3.051c0.626,1.595,0.483,2.034,0.097,2.807c-0.386,0.773-0.386,1.066-0.386,3.438
          c0,2.372-0.051,9.586-0.051,12.003s0.63,3.144,2.179,3.144s2.081-0.479,2.468-1.451c0.386-0.974,0.533-4.063,0.726-5.616
          c0.193-1.552,0.63-6.773,0.63-7.746c0-0.958,0.579-1.878,1.185-1.878c0.052,0,0.093,0.008,0.14,0.008
          c0.098-0.154,0.209-0.301,0.341-0.447c0.702-0.758,1.733-1.144,3.062-1.144c0.274,0,0.537,0.016,0.784,0.047
          c-0.135-0.386-0.316-0.656-0.479-0.943c-0.337-0.575,0.146-1.688,0.386-2.366c0.243-0.677,2.132-5.616,2.132-5.616
          s11.038-7.16,11.373-7.79c0.588-1.085,0.34-2.224,0.34-2.224S47.403-4.234,47.377-6.072z M28.526,8.495
          c2.274,0,3.387-2.815,3.387-4.345c0-2.402-1.518-4.221-3.387-4.221c-1.872,0-3.39,1.819-3.39,4.221
          C25.137,5.842,26.398,8.495,28.526,8.495z M32.96,22.912c-3.012,0-2.698,2.471-2.622,3.49c0.077,1.02,1.8,13.069,1.8,13.069h3.29
          c0,0,0.192-7.438,0.231-9.67c0.039-2.226,0.116-3.09,0.116-3.716C35.779,25.461,35.977,22.912,32.96,22.912z M37.188-2.218
          c0.695,0,1.26-0.563,1.26-1.259v-1.016h0.506v2.178c0,0.695,0.564,1.259,1.259,1.259c0.695,0,1.259-0.564,1.259-1.259v-2.178
          h1.587c0.004-0.629,0.004-1.155,0.004-1.483c0-0.081,0.009-0.155,0.013-0.231h-1.604v-2.182c0-0.695-0.563-1.259-1.259-1.259
          c-0.694,0-1.259,0.564-1.259,1.259v2.178h-0.506v-1.016c0-0.695-0.564-1.259-1.26-1.259S35.93-7.922,35.93-7.227v3.75
          C35.93-2.782,36.493-2.218,37.188-2.218z M54.058-8.486c-0.698,0-1.259,0.564-1.259,1.259v1.02h-0.51v-2.182
          c0-0.695-0.563-1.259-1.259-1.259c-0.694,0-1.256,0.564-1.256,1.259v2.178h-1.069c0,0.042,0.009,0.077,0.009,0.12
          c0.004,0.363,0.015,0.931,0.022,1.599h1.034v2.178c0,0.695,0.565,1.259,1.26,1.259c0.695,0,1.259-0.564,1.259-1.259v-2.178h0.506
          v1.016c0,0.695,0.564,1.259,1.259,1.259c0.695,0,1.259-0.563,1.259-1.259v-3.75C55.312-7.922,54.753-8.486,54.058-8.486z
          M7.619,26.635h-0.68v-2.179c0-0.695-0.564-1.266-1.255-1.266c-0.695,0-1.259,0.57-1.259,1.266v2.179h-0.51v-1.02
          c0-0.695-0.563-1.259-1.259-1.259c-0.695,0-1.259,0.563-1.259,1.259v3.753c0,0.695,0.563,1.251,1.259,1.251
          c0.696,0,1.259-0.556,1.259-1.251v-1.02h0.506v2.179c0,0.694,0.567,1.251,1.259,1.251c0.695,0,1.259-0.557,1.259-1.251v-2.179
          H7.63C7.506,27.715,7.549,27.083,7.619,26.635z M19.524,24.356c-0.694,0-1.259,0.563-1.259,1.259v1.012h-0.51v-2.178
          c0-0.688-0.56-1.259-1.255-1.259c-0.699,0-1.259,0.57-1.259,1.259v2.178h-1.456c-0.004,0.015-0.004,0.039-0.008,0.046
          c-0.023,0.472-0.05,1.073-0.193,1.676h1.657v2.179c0,0.694,0.563,1.251,1.259,1.251s1.255-0.557,1.255-1.251v-2.179h0.51v1.02
          c0,0.695,0.564,1.251,1.259,1.251c0.691,0,1.26-0.563,1.26-1.251v-3.753C20.784,24.92,20.216,24.356,19.524,24.356z"/>
      </g>
    </g>
  </svg>
);
