import React from 'react';

import PageTitle from '../../components/PageTitle';
import Hero from '../../components/Hero';
import Content from '../../components/Content';

import './About.scss';

import aboutHero from './about-hero.jpg';
import aboutJonathan from './about-jonathan.jpg';
import aboutBex from './about-bex.jpg';
import aboutChris from './about-chris.jpg';
import aboutTaryn from './about-taryn.jpg';

const About = () => (
  <>
    <PageTitle title="About" />
    <Hero>
      <img src={aboutHero} alt="Jonathan and Penny, his dog sat on a bench overlooking rolling fields" />
    </Hero>
    <Content>
      <h1 className="visuallyHidden">About JJP Training</h1>
      <p>Jonathan started JJP Training in 2008 so that clients can exercise from their homes or from the gym. Personal training means that clients can exercise safely, and with most people struggling for time, having the time set aside means that it actually happens. It's not about how much you do, but how you do it.</p>
      <p>The thought of starting an exercise routine can be daunting, so we always meet up for a friendly chat before any training takes place. This gives you the chance to ask any questions, and for us to get to know a bit about you and start to put together the best possible routine for you and your lifestyle.</p>
      <h2 className="mainHeading">Meet our trainers</h2>
      <div className="about__trainers">
        <div className="about__trainers__bio">
          <img className="about__trainers__bio__image" src={aboutJonathan} alt="Jonathan walking along a path with his dog Penny" />
          <h3>Jonathan</h3>
          <p>Jonathan completed his degree in Sport and Exercise Science and has worked as a personal trainer since 2008, mostly specialising in general fitness, functional training and weight loss.</p>
          <p>He likes to spend his spare time practicing his guitar, walking his dog Penny in the country, training for obstacle courses, and eating dinner out. He is also learning Spanish in his spare time.</p>
        </div>
        <div className="about__trainers__bio">
          <img className="about__trainers__bio__image" src={aboutBex} alt="Bex smiling, holding a green apple" />
          <h3>Bex</h3>
          <p>Bex completed her degree in finance, and then decided it was just not for her. Bex's true passion is in fitness, and she has competed in and officiated in triathlons for many years now.</p>
          <p>Bex specialises in triathlon and endurance training as well as general fitness, and is also a swimming coach. In Bex's spare time, you can usually find her beating her previous personal best on the exercise bike. Bex also loves to go traveling, and many of her events take her all around Europe.</p>
        </div>
      </div>
      <div className="about__trainers">
        <div className="about__trainers__bio">
          <img className="about__trainers__bio__image" src={aboutChris} alt="Chris exercising with a kettle bell" />
          <h3>Chris</h3>
          <p>Chris has worked in the Leisure industry for six years with a passion for fitness in the gym environment, and qualified as a personal trainer in 2017. His focus is on supporting clients, helping them reach achievable goals and maintaining a healthy lifestyle.</p>
          <p>Chris has recently spent several months backpacking through New Zealand and South East Asia. He enjoys running, cycling and playing his guitar in his spare time.</p>
        </div>
        <div className="about__trainers__bio">
          <img className="about__trainers__bio__image" src={aboutTaryn} alt="Taryn smiling whilst sat on a stationary exercise bike" />
          <h3>Taryn</h3>
          <p>As a mother of two children, Taryn appreciates the importance of being fit and healthy. Taryn leads a very active life, with running and Crossfit playing a big part in her training regime.</p>
          <p>Taryn is very keen on passing on to her clients some of the benefits that her lifestyle has given her, so that they too can get the most out of life.</p>
        </div>
      </div>
      <p className="about__repsStatement">Our trainers are fully qualified, CIMPSA registered, fully insured and first aid trained.</p>
    </Content>
  </>
);

export default About;
