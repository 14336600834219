import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { SITE_NAME } from '../../constants';

const PageTitle = ({ title }) => (
  <Helmet>
    <title>{`${title ? `${title} - ` : ''}${SITE_NAME}`}</title>
  </Helmet>
);

PageTitle.propTypes = {
  title: PropTypes.string,
};

PageTitle.defaultProps = {
  title: null,
};

export default PageTitle;
