import React from 'react';
import { Link } from 'react-router-dom';

import Content from '../../components/Content';
import Hero from '../../components/Hero';
import PageTitle from '../../components/PageTitle';

import nutritionHero from './nutrition-hero.jpg';
import susieProfile from './susie-square.jpg';

import './Nutrition.scss';

const Nutrition = () => (
  <>
    <PageTitle title="Nutrition" />
    <Hero>
      <img src={nutritionHero} alt="A bowl of fruit, fresh vegetables, leaves and half a poached egg" />
    </Hero>
    <Content>
      <h1 className="mainHeading">Nutrition</h1>
      <div className="nutrition">
        <div className="nutrition__text">
          <p className="nutrition__text__user">Susie Alderson MSc MAR mBANT CNHC</p>
          <p className="nutrition__text__content">Our health is very important, and what we eat will influence it. Even our genes can be expressed in different ways depending on what we eat. Nutritional Therapy is based on scientific research, providing a personalised solution for optimal health.</p>
          <p className="nutrition__text__content">Susie is a registered Nutritionist, helping clients optimise their health through their diet whether it is weight loss, weight gain, sporting performance, or food intolerances.</p>
          <p className="nutrition__text__content">If you are constantly tired, out of shape, and not feeling your best then the right combination of exercise and nutrition is needed. Exercise and nutrition go hand in hand, and with Susie’s friendly and realistic approach, she will ensure the best possible plan for you. Susie also has a background in psychology so will be able to implement long term healthy habits that will personally work for you.</p>
          <p className="nutrition__text__content">Arrange your FREE 15 minute nutrition assessment now:</p>
          <p className="nutrition__text__cta"><Link className="nutrition__text__cta__button" to="/contact">Book now</Link></p>
        </div>
        <img className="nutrition__image" src={susieProfile} alt="Profile of Susie Alderson smiling" />
      </div>
    </Content>
  </>
);

export default Nutrition;
