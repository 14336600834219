import React, { Component } from 'react';

import Content from '../../components/Content';
import Hero from '../../components/Hero';
import PageTitle from '../../components/PageTitle';

import onlineHero from './online-hero.jpg';

import './OnlinePT.scss';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
};

class OnlinePTBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      age: '',
      email: '',
      telephone: '',
      desiredAcheivement: '',
      equipment: '',
      injuries: '',
      sessionFrequency: '1 session',
      terms: false,
      honeypot: '',
    }
  }

  handleSubmit = (evt) => {
    try {
      evt.preventDefault();
    } catch (e) {}
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "onlinePT", ...this.state })
    })
      .then(() => alert('Form submitted, we will be in touch soon!'))
      .then(() => window.location = '/')
      .catch(error => alert(error));
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeCheckbox = (e) => {
    const name = e.target.name;
    this.setState({ [name]: !this.state[name] });
  }

  render() {
    const {
      honeypot,
      name,
      age,
      email,
      telephone,
      desiredAcheivement,
      equipment,
      injuries,
      // sessionFrequency,
      terms,
    } = this.state;
    return (
      <>
        <PageTitle title="Online Personal Training" />
        <Hero>
        <img src={onlineHero} alt="Jonathan running towards the camera" />
        </Hero>
        <Content>
        <h1 className="mainHeading onlinePT__mainHeading">Exercise online from anywhere with your own personal trainer</h1>
        <div className="onlinePT">
          <p className="onlinePT__pullout onlinePT__pullout--small">To start training with a fitness professional online, please fill in the form below and we’ll be in touch to find out more about you and arrange your online consultation.</p>
          <form className="onlinePT__form" onSubmit={this.handleSubmit}>
            <p className="visuallyHidden">Do not add anything to the field named "honeypot" as it is there purely to catch spam and robots. <input type="text" name="honeypot" value={honeypot} onChange={(evt) => this.handleChange('honeypot', evt)} /></p>
            <fieldset className="onlinePT__form__fieldset">
              <legend className="onlinePT__form__fieldset__legend">About you</legend>
              <div className="onlinePT__form__inputHolder">
                <input className="onlinePT__form__inputHolder__input" id="name" name="name" type="text" placeholder=" " value={name} onChange={this.handleChange} required />
                <label htmlFor="name" className="onlinePT__form__inputHolder__label">Your name</label>
              </div>
              <div className="onlinePT__form__inputHolder">
                <input className="onlinePT__form__inputHolder__input" id="age" name="age" type="number" placeholder=" " value={age} min="18" max="100" onChange={this.handleChange} required />
                <label htmlFor="age" className="onlinePT__form__inputHolder__label">Your age</label>
              </div>
              <div className="onlinePT__form__inputHolder">
                <input className="onlinePT__form__inputHolder__input" id="email" name="email" type="text" placeholder=" " value={email} onChange={this.handleChange} required />
                <label htmlFor="email" className="onlinePT__form__inputHolder__label">Your email address</label>
              </div>
              <div className="onlinePT__form__inputHolder onlinePT__form__inputHolder--mobile">
                <input className="onlinePT__form__inputHolder__input" id="telephone" name="telephone" type="text" placeholder=" " value={telephone} onChange={this.handleChange} required />
                <label htmlFor="telephone" className="onlinePT__form__inputHolder__label">Your mobile phone number</label>
              </div>
              <div className="onlinePT__form__inputHolder onlinePT__form__inputHolder--injuries onlinePT__form__inputHolder--textarea">
                <textarea className="onlinePT__form__inputHolder__input onlinePT__form__inputHolder__input--textarea" id="injuries" name="injuries" type="text" placeholder=" " rows={4} value={injuries} onChange={this.handleChange} required />
                <label htmlFor="injuries" className="onlinePT__form__inputHolder__label onlinePT__form__inputHolder__label--textarea">Do you have any injuries or conditions that could challenge your ability to exercise?</label>
              </div>
            </fieldset>
            <fieldset className="onlinePT__form__fieldset">
              <legend className="onlinePT__form__fieldset__legend">About your training</legend>
              <div className="onlinePT__form__inputHolder onlinePT__form__inputHolder--achieve onlinePT__form__inputHolder--textarea">
                <textarea className="onlinePT__form__inputHolder__input onlinePT__form__inputHolder__input--textarea" id="desiredAcheivement" name="desiredAcheivement" type="text" placeholder=" " rows={4} value={desiredAcheivement} onChange={this.handleChange} required />
                <label htmlFor="desiredAcheivement" className="onlinePT__form__inputHolder__label onlinePT__form__inputHolder__label--textarea">Briefly, what you would like to achieve from the training?</label>
              </div>
              <div className="onlinePT__form__inputHolder onlinePT__form__inputHolder--equipment onlinePT__form__inputHolder--textarea">
                <textarea className="onlinePT__form__inputHolder__input onlinePT__form__inputHolder__input--textarea" id="equipment" name="equipment" type="text" placeholder=" " rows={4} value={equipment} onChange={this.handleChange} required />
                <label htmlFor="equipment" className="onlinePT__form__inputHolder__label onlinePT__form__inputHolder__label--textarea">What, if any,  equipment do you have available to use?</label>
              </div>
            </fieldset>
            <fieldset className="onlinePT__form__fieldset">
              <legend className="onlinePT__form__fieldset__legend">Terms and conditions</legend>
              <div className="onlinePT__form__terms">
                <p>I understand that I should visit my GP before starting any training sessions and know of no reason why I should not participate in any exercise sessions. I therefore declare myself free of any illness, disease, impairment, infirmity or condition that may be affected by participation in exercise sessions by JJP&nbsp;Training&nbsp;Ltd.</p>
                <p>I understand that I would be participating entirely at my own risk and waive any legal recourse for damages or death to myself or damage to property arising from my participation. Sessions will only be designed and sent once payment is received. Payment is non refundable but may be stopped at any time by the client or the trainer.</p>
                <p>I understand that sessions will not begin until payment has been made to JJP&nbsp;Training&nbsp;Ltd. I also understand that the method of payment will be agreed upon by both the trainer and myself.</p>
                <p>I agree that I am 18 years old or older.</p>
              </div>
              <p className="onlinePT__form__termsAgreement">
                <input type="checkbox" checked={terms} id="terms" onChange={this.handleChangeCheckbox} name="terms" required />
                <label htmlFor="terms">By ticking this box, you agree that you have <strong>read</strong>, <strong>understand</strong> and <strong>accept</strong> the terms outlined above.</label>
              </p>
            </fieldset>
            <p className="onlinePT__text__cta"><input type="submit" className="onlinePT__text__cta__button" value="Submit form" /></p>
          </form>
        </div>
        </Content>
      </>
    );
  }
}
  
export default OnlinePTBooking;
  