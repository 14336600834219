import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Slideshow.scss';

class Slideshow extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      currentImage: 0,
    };
  }

  componentDidMount() {
    const { images } = this.props;
    this.timer = setInterval(() => {
      const { currentImage } = this.state;
      this.setState({
        currentImage: (currentImage + 1) % images.length,
      });
    }, 6000);
  }

  componentWillUnmount() { 
    clearInterval(this.timer);
  }

  render() {
    const { images } = this.props;
    const { currentImage } = this.state;
    return (
      <div className="slideshow">
        {
          images.map((image, i) => (
            <img key={image} className={`slideshow__fitter${currentImage === i ? ' slideshow__fitter--active' : ''}`}
              role="none"
              alt=""
              src={image}
            />
          ))
        }
      </div>
    );
  }
}

Slideshow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

Slideshow.defaultTypes = {
  images: [],
};

export default Slideshow;
