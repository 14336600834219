import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { MENU_ITEMS } from '../../constants'

import { Logo } from './Logo';

import './Header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { menuActive: false };
  }
  toggleState = (evt, off) => {
    this.setState({ menuActive: !this.state.menuActive });
    document.body.classList[this.state.menuActive === true || off === true ? 'remove' : 'add']('menu--active');
    document.documentElement.classList[this.state.menuActive === true || off === true ? 'remove' : 'add']('menu--active');
    window.scrollBy(0,1);
  }
  render() {
    const { currentPage } = this.props;
    const { menuActive } = this.state;
    return (
      <header className="header">
        <div className="header__container">
          <span className="header__logoHolder">
            <Link className="header__logoHolder__link" to="/" title="Home">
              <span className="header__logoHolder__link__image" role="presentation"><Logo /></span>
              <span className="header__logoHolder__link__text">JJP Training</span>
            </Link>
          </span>
          <nav aria-label="Main" className="header__nav">
            <div className="mobileMenu">
              <input className="mobileMenu__input" id="mobileMenuTrigger" type="checkbox" checked={menuActive} onChange={this.toggleState} />
              <label className="mobileMenu__label" htmlFor="mobileMenuTrigger">
                <span className="visuallyHidden">Toggle menu</span>
              </label>
            </div>
            <ul className={`header__nav__list ${menuActive ? 'header__nav__list--active' : ''}`}>
              {
                MENU_ITEMS.map(({ name, link, showMenuItem = true }) => (
                  showMenuItem
                    ? (
                      <li key={link} className="header__nav__list__item">
                        <Link
                          className={
                            `header__nav__list__item__link${
                              currentPage === name ? ' header__nav__list__item__link--active' : ''
                            }`
                          }
                          to={link}
                          onClick={() => { this.toggleState(null, true); window.scrollTo(0,0); }}
                        >
                          {name}
                        </Link>
                      </li>
                    )
                    : null
                ))
              }
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  currentPage: PropTypes.oneOf(MENU_ITEMS.map(({name}) => name)),
};

Header.defaultProps = {
  currentPage: null,
};

export default Header;
