import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Testimonials.scss';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      currentPage: 0,
    };
    const { quotes } = props;
    this.pages = quotes.reduce(function(result, value, index, array) {
      if (index % 2 === 0)
        result.push(array.slice(index, index + 2));
      return result;
    }, []);
  }

  componentDidMount() {
    const pages = this.pages;
    this.timer = setInterval(() => {
      const { currentPage } = this.state;
      this.setState({
        currentPage: (currentPage + 1) % pages.length,
      });
    }, 6000);
  }

  componentWillUnmount() { 
    clearInterval(this.timer);
  }

  render() {
    const { currentPage } = this.state;
    const { showPrefix } = this.props;
    const pages = this.pages;
    return (
      <aside className="testimonials">
        {showPrefix ? (<p className="testimonials__pullout">Don't take our word for it,<br />read what our customers say:</p>) : null}
        <div className="testimonials__list__container">
          <ul className="testimonials__list">
            {
              pages.map((page, index) => (
                <li
                  key={`page--${index}`}
                  className={`testimonials__list__page${
                    currentPage === index
                      ? ' testimonials__list__page--current'
                      : currentPage > index
                        ? ' testimonials__list__page--previous'
                        : ' testimonials__list__page--next'
                  }`}
                >
                  {
                    page.map(({ quote, author }) => (
                      <div key={author} className="testimonials__list__page__quote">
                        <p className="testimonials__list__page__quote__text">{quote}</p>
                        <small className="testimonials__list__page__quote__author">{author}</small>
                      </div>
                    ))
                  }
                </li>
              ))
            }
          </ul>
        </div>
      </aside>
    );
  }

}

Testimonials.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.shape({
    quote: PropTypes.string,
    author: PropTypes.string,
  })),
  showPrefix: PropTypes.bool,
};

Testimonials.defaultProps = {
  quotes: [],
  showPrefix: true,
};

export default Testimonials;
