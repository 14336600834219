import React from 'react';

import './Footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__socialCopy__holder">
      <div className="footer__socialCopy">
        <nav aria-label="Social" className="footer__social">
          <ul className="footer__social__list">
            <li className="footer__social__list__item">
              <a
                className="footer__social__list__item__link footer__social__list__item__link--facebook"
                href="https://facebook.com/JJPTraining/"
              >
                <span className="footer__social__list__item__link__image">Facebook</span>
              </a>
            </li>
            <li className="footer__social__list__item">
              <a
                className="footer__social__list__item__link footer__social__list__item__link--twitter"
                href="https://twitter.com/jjp_training"
              >
                <span className="footer__social__list__item__link__image">Twitter</span>
              </a>
            </li>
          </ul>
        </nav>
        <small className="footer__copyright">
          &copy; Jonathan Popple<br />JJP Training Ltd. 2008-{`${new Date().getFullYear()}`.substr(2)}
        </small>
      </div>
    </div>
    <aside className="footer__companyreg">
      <p>Company Registration number 09310420</p>
      <p>Registered address: 4 Poplar Close, Chesham, Bucks HP5 3HR</p>
    </aside>
  </footer>
);

export default Footer;
