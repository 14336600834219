import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Header from '../../components/Header';
import Main from '../../components/Main';
import Footer from '../../components/Footer';

import PageTitle from '../../components/PageTitle';

import { MENU_ITEMS } from '../../constants.js'

const RootPage = ({ location: { pathname } }) => (
  <>
    <PageTitle />
    <Header currentPage={MENU_ITEMS.filter(({ link }) => link === pathname).reduce((acc, { name }) => `${acc}${name}`, '')} />
    <Main>
      {
        MENU_ITEMS.map(({ link, component: Component, exact }) => <Route key={link} path={link} exact={exact} component={Component} />)
      }
    </Main>
    <Footer />
  </>
);

RootPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default RootPage;
