import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Content from '../../components/Content';
import Hero from '../../components/Hero';
import PageTitle from '../../components/PageTitle';

import offersHero from './offers-hero.jpg';
import bexPool from './bexpool.jpg';

import './OffersAndVouchers.scss';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
};

const VOUCHER_TYPE = {
  CUSTOM: 'custom',
  SPECIFIED: 'specified',
};

class OffersAndVouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherType: VOUCHER_TYPE.CUSTOM,
      name: '',
      recipient: '',
      email: '',
      address: '',
      customValue: '35',
      specifiedVoucher: '2 x 60min',
      honeypot: '',
    }
  }

  handleSelectVoucher(type) {
    this.setState({
      voucherType: type,
    });
  }

  handleSubmit = (evt) => {
    try {
      evt.preventDefault();
    } catch (e) {}
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "voucher", ...this.state })
    })
      .then(() => alert('Form submitted, we will be in touch soon!'))
      .then(() => window.location = '/')
      .catch(error => alert(error));
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const {
      name,
      recipient,
      email,
      address,
      honeypot,
    } = this.state;
    return (
      <>
        <PageTitle title="Offers &amp; Vouchers" />
        <Hero>
          <img src={offersHero} alt="Jonathan performing a pull up on some gym equipment" />
        </Hero>
        <Content>
          <h1 className="visually_hidden">Offers &amp; Vouchers</h1>
          <div className="offersAndVouchers">
            <div className="offersAndVouchers__col">
              <h2 className="mainHeading"><span className="mainHeading__content">Latest Offers</span></h2>
              <p>Free circuit training session with your first block of 5 personal training sessions.</p>
              <p>Complete the form on the <Link to="/contact">Contact</Link> page to arrange yours.</p>
              <small>(Available to new clients only)</small>
              <h3>Getting Married?</h3>
              <p>Free entry to Saturday morning circuit class up until your wedding day when you have one or more PT sessions per week!</p>
              <img src={bexPool} className="offersAndVouchers__image" alt="Bex lifting a punchbag weight next to a swimming pool" />
            </div>
            <div className="offersAndVouchers__col">
              <h2 className="mainHeading"><span className="mainHeading__content">Vouchers</span></h2>
              <p>If you’d like to buy a friend a voucher for a Birthday or Christmas present, please complete the form below:</p>
              <form onSubmit={this.handleSubmit}>
                <p className="visuallyHidden">Do not add anything to the field named "honeypot" as it is there purely to catch spam and robots. <input type="text" name="honeypot" onChange={this.handleChange} value={honeypot} /></p>
                <div className="offersAndVouchers__inputHolder">
                  <input className="offersAndVouchers__inputHolder__input" id="yourName" name="name" type="text" placeholder=" " value={name} onChange={this.handleChange} required />
                  <label htmlFor="yourName" className="offersAndVouchers__inputHolder__label">Your name</label>
                </div>
                <div className="offersAndVouchers__inputHolder">
                  <input className="offersAndVouchers__inputHolder__input" id="yourEmail" name="email" type="email" placeholder=" " value={email} onChange={this.handleChange} required />
                  <label htmlFor="yourEmail" className="offersAndVouchers__inputHolder__label">Your email</label>
                </div>
                <div className="offersAndVouchers__inputHolder">
                  <input className="offersAndVouchers__inputHolder__input" id="recipientName" name="recipient" type="text" placeholder=" " value={recipient} onChange={this.handleChange} required />
                  <label htmlFor="recipientName" className="offersAndVouchers__inputHolder__label">Recipient name</label>
                </div>
                <div className="offersAndVouchers__inputHolder">
                  <textarea className="offersAndVouchers__inputHolder__input offersAndVouchers__inputHolder__input--textarea" id="postalAddress" name="address" placeholder=" " value={address} onChange={this.handleChange} required rows={4} />
                  <label htmlFor="postalAddress" className="offersAndVouchers__inputHolder__label offersAndVouchers__inputHolder__label--textarea">Postal address to send voucher to</label>
                </div>
                <fieldset className="offersAndVouchers__form__fieldset">
                  <legend className="visually_hidden">Value of voucher</legend>
                  <div className="offersAndVouchers__form__item">
                    <label htmlFor="valueChoiceCustom" className="visually_hidden">Voucher type</label>
                    <input
                      type="radio"
                      id="valueChoiceCustom"
                      name="voucherType"
                      value="Custom value"
                      defaultChecked
                      onClick={() => { this.handleSelectVoucher(VOUCHER_TYPE.CUSTOM); }}
                      onChange={this.handleChange}
                    />
                    <label htmlFor="customValue" className="visually_hidden">Custom value</label>
                    <span className="offersAndVouchers__form__item__field__moneyHolder">
                      <input
                        id="customValue"
                        name="customValue"
                        type="number"
                        placeholder="Custom amount"
                        defaultValue="35"
                        required
                        className="offersAndVouchers__form__item__field offersAndVouchers__form__item__field--money"
                        onFocus={() => { this.handleSelectVoucher(VOUCHER_TYPE.CUSTOM); }}
                        onChange={this.handleChange}
                      />
                    </span>
                  </div>
                  <div className="offersAndVouchers__form__item">
                    <label htmlFor="valueChoiceSpecified" className="visually_hidden">Voucher type</label>
                    <input
                      type="radio"
                      id="valueChoiceSpecified"
                      name="voucherType"
                      value="Specified value"
                      onClick={() => { this.handleSelectVoucher(VOUCHER_TYPE.SPECIFIED)} }
                      onChange={this.handleChange}
                    />
                    <label htmlFor="specifiedVoucher" className="visually_hidden">Specified voucher</label>
                    <select
                      id="specifiedVoucher"
                      name="specifiedVoucher"
                      defaultValue="2 x 60min"
                      required
                      onClick={() => { this.handleSelectVoucher(VOUCHER_TYPE.SPECIFIED); }}
                      onFocus={() => { this.handleSelectVoucher(VOUCHER_TYPE.SPECIFIED); }}
                      onChange={this.handleChange}
                    >
                      <option value="30min">30 minute Personal Trainer session</option>
                      <option value="2x 30min">2 x 30 minute Personal Trainer session</option>
                      <option value="5 x 30min">5 x 30 minute Personal Trainer session</option>
                      <option value="10 x 30min">10 x 30 minute Personal Trainer session</option>
                      <option value="60min">60 minute Personal Trainer session</option>
                      <option value="2 x 60min">2 x 60 minute Personal Trainer session</option>
                      <option value="5 x 60min">5 x 60 minute Personal Trainer session</option>
                      <option value="10 x 60min">10 x 60 minute Personal Trainer session</option>
                    </select>
                  </div>
                </fieldset>
                <div className="offersAndVouchers__form__item">
                  <button type="submit">Request Voucher</button>
                </div>
              </form>
            </div>
          </div>
        </Content>
      </>
    );
  }
}

export default OffersAndVouchers;
