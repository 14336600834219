import React from 'react';
import { Link } from 'react-router-dom';
import { Player } from 'video-react';

import Content from '../../components/Content';
import Hero from '../../components/Hero';
import PageTitle from '../../components/PageTitle';
import Testimonials from '../../components/Testimonials';

import onlineHero from './online-hero.jpg';
import onlineVideo from './jjp-online-training-2020.mp4';

import './OnlinePT.scss';

const shuffleArray = (original) => {
  const array = [...original];
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const quotes = [
  {
    quote: "I was able to continue my training when I was on holiday",
    author: 'Tom, Chesham',
  },
  {
    quote: "I am always away on business so this makes my training regular",
    author: 'Matt, Nottingham',
  },
  {
    quote: "My availability changes from day to day, so this gives me flexibility",
    author: 'Rich, Aylesbury',
  },
  {
    quote: "I love that the sessions are tailored to me, and if I have any problems I can contact Jonathan",
    author: 'Emma, High Wycombe',
  },
]

const OnlinePT = () => (
  <>
    <PageTitle title="Online Personal Training" />
    <Hero>
      <img src={onlineHero} alt="Jonathan running towards the camera" />
    </Hero>
    <Content>
      <h1 className="mainHeading onlinePT__mainHeading">Online Personal Training</h1>
      <p className="onlinePT__pullout onlinePT__pullout--first">Train from anywhere with one of our fitness professionals!</p>
      <p className="onlinePT__pullout onlinePT__pullout--small">Watch the video below to see how it works:</p>
      <Player
        playsInline
        src={onlineVideo}
      />
      <div className="onlinePT">
        <p className="onlinePT__text__cta"><Link className="onlinePT__text__cta__button" to="/online/booking">Start now!</Link></p>
      </div>
    </Content>
    <div className="onlinePT">
      <Testimonials showPrefix={false} quotes={shuffleArray(quotes)} />
    </div>
    <p className="onlinePT__pullout">
      <small>Terms and conditions apply.</small>
    </p>
  </>
);

export default OnlinePT;
