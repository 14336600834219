import React from 'react';
import { Link } from 'react-router-dom';

import Slideshow from '../../components/Slideshow';
import Testimonials from '../../components/Testimonials';
import LatestTweets from '../../components/LatestTweets';

import bexropes from './slideshow/bexropes.jpg';
import christension from './slideshow/christension.jpg';
import chrisweight from './slideshow/chrisweight.jpg';
import jdip from './slideshow/jdip.jpg';
import jrunning from './slideshow/jrunning.jpg';
import taryn2 from './slideshow/taryn-2.jpg';
import taryn3 from './slideshow/taryn-3.jpg';
import van from './slideshow/van.jpg';

import './Home.scss';

const shuffleArray = (original) => {
  const array = [...original];
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const images = [
  bexropes,
  christension,
  chrisweight,
  jdip,
  jrunning,
  taryn2,
  taryn3,
  van,
];

const quotes = [
  {
    quote: "My trainer's qualifications are up to date so I know I'm doing things properly",
    author: 'Iain Dallas, OnIT',
  },
  {
    quote: "My trainer motivates me but doesn't push me beyond my capability",
    author: 'Jamie Cassidy, Porter Brown',
  },
  {
    quote: "My blood pressure has reduced, my GP is delighted",
    author: 'Jane Elkins',
  },
  {
    quote: "I wish I started sooner, I'm no longer wasting my time at the gym",
    author: 'Daniel Miller',
  },
  {
    quote: "Don't hesitate, make that all important call!",
    author: 'Paul Maxwell',
  },
  {
    quote: "Varied and interesting, yet tailored to need and age",
    author: 'Georgina Mead',
  },
  {
    quote: "I am constantly kept motivated therefore hitting my targets",
    author: 'Samantha Bassett',
  },
  {
    quote: "I've lost weight, have more energy, sleep better and feel great",
    author: 'Ryan Eames',
  },
  {
    quote: "Bex is the best PT I've ever had. I hate exercise, but Bex makes it fun!",
    author: 'Anna Champion',
  },
  {
    quote: "Bex is fantastic, my cycling ability has improved so quickly.",
    author: 'Kelly Morgan',
  },
  {
    quote: "Every session is varied to keep it interesting!",
    author: 'Marie Whittaker',
  },
]

const Home = () => (
  <>
    <Slideshow images={shuffleArray(images)} />
    <div className="home">
      <section className="home__cta">
        <p className="home__cta__pullout">
          Your health is our priority
          <Link to="/contact" className="home__cta__pullout__button">Book now</Link>
        </p>
        <p className="home__cta__content">JJP Training Ltd is a small personal training company based in Chesham UK, working with clients to achieve optimal health and fitness.</p>
      </section>
    </div>
    <Testimonials quotes={shuffleArray(quotes)} />
    <div className="home__tweets">
      <h2 className="visually_hidden">Twitter</h2>
      <div className="home__tweets__user">
        <p className="home__tweets__user__name">JJP Training</p>
        <p className="home__tweets__user__link"><a href="https://twitter.com/jjp_training">@JJP_Training</a></p>
      </div>
      <div className="home__tweets__follow">
        <a
          href="https://twitter.com/jjp_training?ref_src=twsrc%5Etfw"
          className="twitter-follow-button"
          data-size="large"
          data-show-screen-name="false"
          data-show-count="false"
        >
          Follow @jjp_training
        </a>
      </div>
    </div>
    <LatestTweets />
  </>
);

export default Home;
