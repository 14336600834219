import Home from './pages/Home';
import About from './pages/About';
import Nutrition from './pages/Nutrition';
import OffersAndVouchers from './pages/OffersAndVouchers';
import Pricing from './pages/Pricing';
import OnlinePT from './pages/OnlinePT';
import OnlinePTBooking from './pages/OnlinePT/form';
import Contact from './pages/Contact';

export const SITE_NAME = 'JJP Training';

export const MENU_ITEMS = [
  {
    name: 'Home',
    link: '/',
    component: Home,
    exact: true,
  },
  {
    name: 'About',
    link: '/about',
    component: About,
  },
  {
    name: 'Nutrition',
    link: '/nutrition',
    component: Nutrition,
  },
  {
    name: 'Offers & Vouchers',
    link: '/offers-vouchers',
    component: OffersAndVouchers,
  },
  {
    name: 'Pricing',
    link: '/pricing',
    component: Pricing,
  },
  {
    name: 'Online PT',
    link: '/online',
    component: OnlinePT,
    exact: true,
  },
  {
    name: 'Home',
    link: '/online/booking',
    showMenuItem: false,
    component: OnlinePTBooking,
    exact: true,
  },
  {
    name: 'Contact',
    link: '/contact',
    component: Contact,
  },
];
