import React from 'react';
import PropTypes from 'prop-types';

import './Content.scss';

const Content = ({ children }) => (
  <main className="content">
    {children}
  </main>
);

Content.propTypes = {
  children: PropTypes.any,
};

Content.defaultProps = {
  children: null,
};

export default Content;
