import React, { Component } from 'react';
import { Map, Circle, TileLayer } from 'react-leaflet'

import Content from '../../components/Content';
import Hero from '../../components/Hero';
import PageTitle from '../../components/PageTitle';

import contactHero from './contact-hero.jpg';

import './Contact.scss';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
};

const position = [51.708928, -0.612318];
const radius = 4828.03;

const AreaMap = () => (
  <Map center={position} zoom={12} style={{ height: 450 }}>
    <TileLayer
      url="https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGlnaXRhbGdyYXZ5IiwiYSI6Ill3di1PV1EifQ.xnnDj07EJhtg0K9fxOiFSw"
      attribution="Map data &copy; <a href=&quothttp://openstreetmap.org&quot>OpenStreetMap</a> contributors, <a href=&quothttp://creativecommons.org/licenses/by-sa/2.0/&quot>CC-BY-SA</a>, Imagery © <a href=&quothttp://mapbox.com&quot>Mapbox</a>"
    />
    <Circle center={position} radius={radius} color="#e54545" fillColor="#e54545" fillOpacity={0.2} />
  </Map>
);

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      telephone: '',
      preference: 'no-preference',
      message: '',
      honeypot: '',
    };
  }

  handleChange = (item, evt) => {
    this.setState({
      [item]: evt.target.value,
    });
  }

  handleSubmit = (evt) => {
    try {
      evt.preventDefault();
    } catch (e) {}
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => alert('Form submitted, we will be in touch soon!'))
      .then(() => window.location = '/')
      .catch(error => alert(error));

    return false;
  }

  render() {
    const {
      name,
      email,
      telephone,
      preference,
      message,
      honeypot,
    } = this.state;
    return (
      <>
        <PageTitle title="Contact" />
        <Hero>
          <img src={contactHero} alt="Jonathan performing a press-up on grass, looking directly at the camera" />
        </Hero>
        <Content>
          <h1 className="mainHeading">Contact</h1>
          <div className="contact">
            <p>For all booking enquiries, or to ask us any question, please complete and send the form below. We will always try to get back to you within 48 hours (please check your junk mail).</p>
            <form className="contact__form" onSubmit={this.handleSubmit}>
              <p className="visuallyHidden">Do not add anything to the field named "honeypot" as it is there purely to catch spam and robots. <input type="text" name="honeypot" value={honeypot} onChange={(evt) => this.handleChange('honeypot', evt)} /></p>
              <div className="contact__form__inputs">
                <div className="contact__form__inputs__inputHolder">
                  <input className="contact__form__inputs__inputHolder__input" required placeholder=" " type="text" name="name" id="name" value={name} onChange={(evt) => this.handleChange('name', evt)} />
                  <label className="contact__form__inputs__inputHolder__label" htmlFor="name">Your name</label>
                </div>
                <div className="contact__form__inputs__inputHolder">
                  <input className="contact__form__inputs__inputHolder__input" required placeholder=" " type="text" name="email" id="email" value={email} onChange={(evt) => this.handleChange('email', evt)} />
                  <label className="contact__form__inputs__inputHolder__label" htmlFor="email">Your email</label>
                </div>
                <div className="contact__form__inputs__inputHolder">
                  <input className="contact__form__inputs__inputHolder__input" required placeholder=" " type="text" name="telephone" id="telephone" value={telephone} onChange={(evt) => this.handleChange('telephone', evt)} />
                  <label className="contact__form__inputs__inputHolder__label" htmlFor="telephone">Your telephone</label>
                </div>
                <div className="contact__form__inputs__selectHolder">
                  <select className="contact__form__inputs__select" name="preference" value={preference} onChange={(evt) => this.handleChange('preference', evt)}>
                    <option value="no-preference">I don't mind who trains me</option>  
                    <option value="male">I would prefer a male trainer</option>  
                    <option value="female">I would prefer a female trainer</option>  
                    <option value="nutritionist">I would like to consult with the nutritionist</option>  
                    <option value="circuit-class">I am interested in outdoor circuit classes</option>  
                  </select>
                </div>
              </div>
              <div className="contact__form__inputs">
                <div className="contact__form__inputs__inputHolder">
                  <textarea 
                    className="contact__form__inputs__inputHolder__input contact__form__inputs__inputHolder__input--textarea"
                    required
                    placeholder=" "
                    name="message"
                    id="message"
                    value={message}
                    onChange={(evt) => this.handleChange('message', evt)}
                    rows={8}
                  />
                  <label className="contact__form__inputs__inputHolder__label contact__form__inputs__inputHolder__label--textarea" htmlFor="message">Your message</label>
                </div>
                <input type="submit" className="contact__form__cta" value="Send message" />
              </div>
            </form>
            <p className="contact__call">or call us on <a href="tel:+447753340984">07753 340 984</a></p>
            <AreaMap />
            <p className="contact__smallprint"><small>Travel up to 3 miles radius is covered in our pricing structure. Any distance further than 3 miles from Chesham Moor Gym & Swim would need to be discussed on an individual basis.</small></p>
          </div>
        </Content>
      </>
    );
  }
}

export default Pricing;
