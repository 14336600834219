import React from 'react';
import PropTypes from 'prop-types';

import './Hero.scss';

const Hero = ({ children }) => (
  <aside className="hero">
    {children}
  </aside>
);

Hero.propTypes = {
  children: PropTypes.any,
};

Hero.defaultProps = {
  children: null,
};

export default Hero;
